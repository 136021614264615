@import "utils";

.title {
  @include text-platform-h4;

  margin-right: rem-calc(4);
  color: getColor($c-text-tertiary);
}

.countdown {
  @include text-platform-h4;
  color: getColor($c-text-secondary);
}
