@import "utils";

.tooltipIcon {
  cursor: pointer;
  border-radius: rem-calc(4);
  margin-right: rem-calc(-6);

  &:hover {
    background-color: transparent;
    border-color: transparent;

    @include icon-styles {
      color: getColor($c-icon);
    }
  }

  @include icon-styles {
    width: 40px;
    height: 40px;
    color: getColor($c-icon-secondary);
  }
}

.FAQTooltip {
  z-index: 2;
}
