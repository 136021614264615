@import "utils";

.wrapper {
  margin: rem-calc(0 16);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: rem-calc(8);
  width: 100%;
}

.counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: rem-calc(6);

  height: rem-calc(32);
  padding: rem-calc(0 12);

  background-color: getColor($c-bg-neutral-tertiary);
  border: 1px solid getColor($c-border);
  border-radius: rem-calc(30);
}

.counterMessage {
  font-family: $f-manrope;
  font-size: rem-calc(12);
  font-weight: $font-weight_bold;
  color: getColor($c-text-default);
}

.title {
  @include text-platform-h2;

  font-weight: $font-weight_extra-bold;
  color: getColor($c-text-default);
  margin-bottom: rem-calc(8);
}

.flex {
  display: flex;
  align-items: center;
  gap: rem-calc(8);
}

.description {
  color: getColor($c-text-default);
  font-weight: $font-weight_medium;
}
