@import "utils";

.wrapper {
  margin: rem-calc(0 16);
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: rem-calc(8);
}

.title {
  @include text-platform-h2;
  font-weight: $font-weight_extra-bold;
  color: getColor($c-text-default);
}

.stepsWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: rem-calc(32);
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: getColor($c-border);
    position: absolute;
    top: 50%;
    left: 0;
  }
}

.leagueStep {
  display: flex;
  border-radius: rem-calc(20);
  background-color: getColor($c-bg-neutral-tertiary);
  padding: rem-calc(11);
  z-index: 1;
  border: 1px solid getColor($c-border);
  box-sizing: content-box;
  height: rem-calc(16); // icon height

  &:global(.is-active) {
    background-color: getColor($c-bg-warning);
    border-color: getColor($c-border-warning);
    cursor: default;
  }
}

.leagueTitle {
  font-weight: $font-weight_bold;
  display: flex;
  align-items: center;

  color: getColor($c-text-default);
  margin-left: rem-calc(8);
}

.toolTipWrapper {
  height: 16px;
}

.countdownWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
