@import "~foundation-sites/scss/foundation";

@import "utils";
@import "reset";
@import "atomic";
@import "links";
@import "icon";
@import "modal";
@import "nprogress";
@import "placesSuggestions";

@include foundation-xy-grid-classes(
  $base-grid: true,
  $margin-grid: true,
  $padding-grid: false,
  $block-grid: false,
  $collapse: false,
  $offset: true,
  $vertical-grid: false,
  $frame-grid: false
);
@include foundation-flex-classes;
@include foundation-prototype-classes;
@include foundation-visibility-classes;
@include foundation-text-alignment;

:root {
  #{$header-height}: 52px;
  #{$footer-height}: 48px;
  #{$landing-header-height}: 72px;
  #{$responsive-modal-handle-height}: 38px;
  #{$scrollbar-width}: 0px;
  #{$sidebar-width}: 320px;
  #{$cta-button-height}: 64px;
  #{$cta-banner-height}: 90px;
  #{$promo-banner-height}: 72px;
  #{$website-page-form-container-width}: 668px;
  #{$website-page-modal-container-width}: 440px;

  #{$platform-container-indent}: rem-calc(16);
  #{$sessions-header-height}: rem-calc(72);
  #{$tech-check-management-header-height}: rem-calc(72);
  #{$platform-header-height}: rem-calc(72);
  #{$topic-select-horizontal-padding}: rem-calc(24);
  #{$chats-header-height}: rem-calc(64);
  #{$chats-mobile-header-height}: rem-calc(56);
  #{$auto-messages-header-height}: rem-calc(72);
  #{$chat-notifications-banner-height}: rem-calc(52);
  #{$mobile-footer-height}: rem-calc(72);
  --vh: 1vh;

  &#{$light-mode-selector},
  &:not([#{$theme-attribute}]) {
    @each $token, $color in set-theme-colors($light-mode-theme) {
      #{getColorVariable($token)}: #{$color};
    }
  }

  &#{$dark-mode-selector} {
    @each $token, $color in set-theme-colors($dark-mode-theme) {
      #{getColorVariable($token)}: #{$color};
    }
  }

  &#{$knowely-light-mode-selector} {
    @each $token, $color in set-theme-colors($knowely-light-mode-theme) {
      #{getColorVariable($token)}: #{$color};
    }
  }

  &#{$knowely-dark-mode-selector} {
    @each $token, $color in set-theme-colors($knowely-dark-mode-theme) {
      #{getColorVariable($token)}: #{$color};
    }
  }

  @include breakpoint(large) {
    #{$sidebar-width}: 5rem;
  }

  @include breakpoint(small down) {
    #{$landing-header-height}: 56px;
    #{$platform-container-indent}: rem-calc(12);
  }
}

html {
  &.has-modal {
    &, body {
      overflow: hidden;
    }
  }

  --content-gap: 4%;

  &:not(.e2e) {
    scroll-behavior: smooth;
  }
}

body {
  font-family: $f-manrope;
  color: getColor($c-text-default);
  box-sizing: border-box;
  position: relative;
  padding-right: var(#{$scrollbar-width});
  background-color: getColor($c-bg-neutral);

  scrollbar-color: getColor($c-scrollbar) transparent;
}

a {
  color: currentColor;
  text-decoration: none;
}

.native a {
  color: getColor($c-text-info);
  text-decoration: underline;
}

#bingc-phone-button,
#bingc-active {
  display: none !important;
}

.grid-container {
  width: 100%;
}

.monaco-aria-container {
  display: none;
}
